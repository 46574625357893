import React, { useState, useContext, useEffect } from 'react';
import { baseUrl } from 'Api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext, AuthContext } from 'Context';
import './UserM.css';
import axios from 'axios';
import RegisterPage from './RegisterPage';
import Affliated from './Affliated';

function UserManger({ setLineReq, getToken, isTokenChange }) {
const { fetchData, apiResponse, isLoading, getAccessChecker } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
useEffect(() => {
  setLineReq(true);
  fetchData();
  return () => {
    setLineReq(false); // This will be called when the component is unmounted
  };
}, [isTokenChange]);
const currentToken = getToken();
useEffect(() => {
  if (getAccessChecker?.success === true) {
    // navigate('/dashboard', { replace: true });
        navigate(-1); // Navigate to the previous page
  }
}, [navigate, location]);
  return (
    <div className="widthMax">
      {isLoading ? (
        // Show loader while waiting for the API response
        <p className="loading">Loading...</p>
      ) : apiResponse?.data?.length > 0 || apiResponse?.data?.manager_status === 'Pending' ? (
        <Affliated apiResponse={apiResponse} />
      ) : (
        <RegisterPage fetchData={fetchData} getToken={getToken} />
      )}
    </div>
  );
}
export default UserManger;
