import { useLocation } from 'react-router-dom';
import styles from './index.module.css';

function FormSection({ children, title }) {
  const location = useLocation();
  const isPayByManager = location.pathname === '/pay-by-manager';
  return (
    <div className={styles.formSection}>
      <h4 className={styles.title}>
        {title}
      </h4>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default FormSection;
