/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress as MuiCircularProgress, StylesProvider, withStyles } from '@material-ui/core';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { AppContext, AuthContext } from 'Context';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import './innerPage.css';
import ProgressBar from 'Components/header/components/ProgressBar';
import backArrowIcon from '../../../Assets/backArrowIcon.svg';
import AlterImage from '../../../Assets/alter-image.png';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export const InnerPage = (isGrid) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAppSnackbar, setVisible } = useContext(AppContext);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryID, setSelectedCategoryID] = useState(undefined);
  const [profileID, setProfileID] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [arrows, setArrows] = useState(false);
  const [loader, setLoader] = useState(true);
  const { mutate: getCategoryDetails } = useSWR([endpoints.getCategoryDetail, profileID, selectedCategoryID], {
    fetcher: (url, user_id, category_id) => dataFetcher(url, { user_id, category_id }),
    onSuccess: ({ success, videos, message }) => {
      // setLoader(true); // Set loader to true when starting to fetch data
      if (success) {
        setSubCategories([{ title: '', videos }]);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoader(false); // Set loader to false when data has been successfully fetched or when an error occurs
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
      setLoader(false); // Ensure loader is set to false on error as well
    },
  });

  useEffect(() => {
    if (!selectedCategoryID) {
      setSelectedCategoryID(location.state.id);
      setProfileID(location.state.profile_id);
      setTitle(location.state.title);
      setThumbnail(location.state.icon);
    }
    getCategoryDetails(endpoints.getCategoryDetail, location.state.profile_id, location.state.id);
  }, [selectedCategoryID, profileID]);

  useEffect(() => {
    if (!selectedCategoryID) {
      setSelectedCategoryID(location.state.id);
      setProfileID(location.state.profile_id);
      setTitle(location.state.title);
      setThumbnail(location.state.icon);
    }
    getCategoryDetails(endpoints.getCategoryDetail, location.state.profile_id, location.state.id);
  }, [selectedCategoryID, profileID]);
  return (
<div>
  {loader === true
  ? <ProgressBar />
: (
  <div>
<div onClick={() => { navigate('/profile'); localStorage.removeItem('prevlocation'); setVisible(true); }} aria-hidden="true">
    <img
      style={{ marginTop: 40, marginLeft: 30, height: 40, cursor: 'pointer' }}
      // className={styles.videoBackButton}
      src={backArrowIcon || AlterImage}
      onError={(e) => {
        e.target.src = AlterImage;
      }}
    />
</div>
  <div className={styles.rectangle}>
    <Box
      className={styles.image}
      style={{
        backgroundImage: `url(${thumbnail})`
      }}
    />
    <div className={styles.titleText}>
      <h3>
        {title}
      </h3>
    </div>
  </div>
  <div className="carousal">
    {!!subCategories?.length && (
      subCategories?.map((subCategory, idx) => (
        !!subCategory?.videos?.length && (
          <ImageGroup
            center="centerMe"
            isGrid
            videos={subCategory?.videos}
            key={idx}
            title={subCategory?.title}
            catG
          />
        )
      ))
    )}
  </div>
  </div>
)}
</div>
  );
};
