import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import useMyAxios from 'hooks/useMyAxios';
import { getAllActivePlans } from 'Api/apiFunctions';
import { ALLOWED_UPLOADS_COUNT } from 'Commons';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import Spinner from 'Components/Spinner';
import styles from './TrailVideoPopUp.module.css';
import image from '../../Assets/frnds.png';

function TrailpopUp({ isVisible, onHide, showVideo }) {
  const { userDetails, setAppSnackbar, getSubscription, autoSignIn } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const spinerSmall = 'spinex';
  const [videoSkipped, setVideoSkipped] = useState(false);
const [loader, setloader] = useState(false);
const {
  response: plans,
  error: plansErr,
  loading: plansLoading,
  refetch: refetchPlans,
} = useMyAxios(getAllActivePlans, null, 'ALL_ACTIVE_PLANS');
const promotionVideoUrl = 'https://projector-optimized.s3.us-west-2.amazonaws.com/production/background-videos/Web.mp4';
const handleBackClick = () => {
  // if video already skipped show previous content
  if (videoSkipped) {
    setVideoSkipped(false);
  } else {
    onHide();
  }
};
  const subscribePlan = async () => {
 setloader(true);
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/subscribePlan`;
      const requestBody = {
        token: myToken,
        plan_id: 11,
        is_free: 0,
        upgrade: true,
        trial: false
      };
      const response = await axios.post(apiUrl, requestBody);
      setAppSnackbar({
        isVisible: true,
        type: 'success',
        message: 'Plan purchased successfully',
      });
      getSubscription();
      autoSignIn();
      onHide();
      setloader(false);
    } catch (error) {
      const responseData = error?.response?.data?.message;
      console.error('error', error);
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: responseData || 'Oops! Something went wrong while purchasing plan',
      });
      setloader(false);
      onHide();
      navigate('/dashboard');
    }
  };
  const handleSkip = () => {
    setVideoSkipped(true);
    // !videoSkipped && onHide();
  };

  const amountOfYear = plans?.data?.filter((obj) => obj?.id === '11');
  const remainingUploads = Math.max(ALLOWED_UPLOADS_COUNT - userDetails.totalContentUploaded, 0);
  return (
<div className={styles.bigframe}>
 <Box className={styles.main} borderRadius="10px">
      <Box
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        width="100%"
        zIndex="1"
        padding="5"
        marginTop="5"
      >
        <Button className="top-btn" onClick={!videoSkipped ? handleSkip : onHide}>
          {!videoSkipped ? 'Continue' : <CloseIcon />}
        </Button>
      </Box>
      <ReactPlayer
        className="videoPlayer"
        url={promotionVideoUrl}
        playing
        muted
        loop
      />
      {videoSkipped && (
 <Box position="absolute" bottom="0" width="100%" height="auto" className={styles.textbox} borderRadius="10px">
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            height="100%"
          >
 <p className={styles.ultimate}>
            Unlock the Ultimate Personal
              <br />
            Viewing Experience
 </p>

 <button
              className={styles.continue}
              disabled={loader}
              onClick={!loader && subscribePlan}
 >
{loader ? <Spinner spinex={spinerSmall} /> : 'Buy Now'}
 </button>
 <div className={styles.stream}>
            Your 14 day trial limit (10 free uploads) has been reached,
              <br />
            please buy now to unlock Projector!
 </div>
            <div className={styles.featuresLabel}>Unlimited Uploads</div>
            <div className={styles.featuresLabel}> Unlimited Cloud Storage</div>
            <div className={styles.featuresLabel}> Unlimited Enjoyment</div>
          </Box>
 </Box>
      )}
 </Box>
</div>

  );
}
export default TrailpopUp;
